import React from "react";
import './InfoForm.css';

import { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import firebaseConfig from '../../Firebase.js';

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const YourFormComponent = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    city: '',
    province: '',
    phoneNumber: '',
    email: '',
    userType: '',
    additionalInfo: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any of the required fields is empty
    const requiredFields = ['firstName', 'lastName', 'phoneNumber', 'email', 'city', 'province', 'userType', 'additionalInfo'];
    const isEmpty = requiredFields.some(field => !formData[field]);
    
    // Looking for empty input fields
    if (isEmpty) {
      alert('Please fill in all required fields');
      return;
    }


    // Get a reference to the database
    const database = firebase.database();

    // Determine the category based on user choice
    const category = formData.userType === 'option1' ? 'tanlent' : 'work';

    // Push user data to the appropriate category
    await database.ref(category).push(formData);
 
    // Reset the form after submission
    setFormData({
      firstName: '',
      lastName: '',
      city: '',
      province: '',
      phoneNumber: '',
      email: '',
      userType: '',
      additionalInfo: ''
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        First Name<span className="required">*</span>:
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
      </label>
      <label>
        Last Name<span className="required">*</span>:
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
      </label>
      <label>
        Phone Number<span className="required">*</span>:
        <input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </label>
      <label>
        Email<span className="required">*</span>:
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </label>
      <label>
        City<span className="required">*</span>:
        <input
          type="text"
          name="city"
          value={formData.city}
          onChange={handleChange}
        />
      </label>
      <label>
        Province<span className="required">*</span>:
        <input
          type="text"
          name="province"
          value={formData.province}
          onChange={handleChange}
        />
      </label>
      <label>
        Looking for Talent or Work<span className="required">*</span>:
        <select
          name="userType"
          value={formData.userType}
          onChange={handleChange}
        >
          <option value="">Select...</option>
          <option value="option1">Talent</option>
          <option value="option2">Work</option>
        </select>
      </label>
      <label>
        Additional Information<span className="required">*</span>:
        <textarea
          name="additionalInfo"
          value={formData.additionalInfo}
          onChange={handleChange}
          placeholder="If you're looking for work tell us about your experience and job preference. If you're looking for talent tell us what type of experience or skills are you looking for?"
        />
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

export default YourFormComponent;