import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <div className="logoContainer">
      <div className="logo">Clikk</div>
    </div>
  );
};

export default Header;
