import "./App.css";
import "./components/LandingPage/LandingPage.css";

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/LandingPage/Header.jsx';
import Descriptions from './components/LandingPage/Descriptions';
import Footer from './components/LandingPage/Footer.jsx';
import InfoForm from './components/LandingPage/InfoForm.jsx';
import PrivacyPolicy from './components/LandingPage/Privacy.jsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<>
          <div className="App">
            <div className="linerGradient">
              <Header />
              <Descriptions />
              <InfoForm />
              <Footer />
            </div>
          </div>
        </>} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}
export default App;

