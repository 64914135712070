import React from 'react';
import './Descriptions.css';

const Descriptions = () => {
    return (
        <div className="descriptionsContainer">
            <div className="slogan">Gateway Where Trade and Talents Meet.</div>
            <div className="aboutUs">About Us</div>
            <div className="desAaboutUs">Welcome to Clikk, Where we’re reshaping the landscape of labour and employment in the modern world. Our mission is to empower both labour workers and companies by providing a platform that simplifies the process of finding the perfect work opportunities and hiring the right talent.</div>
            <div className="ourVision">Our Vision</div>
            <div className="desOurVision">At Clikk, we envision a future where work opportunities are easily accessible, and businesses can effortlessly connect with the skilled workforce they require. We believe in fostering an environment where individuals can build their careers and business can meet their goals.</div>
            <div className="theSolution">The Solution</div>
            <div className="desTheSolution">Clikk bridges the gap between labour workers and companies in need of their services. We offer a user-friendly, innovative, and efficient system that matches labour workers with companies. making the job search and hiring process as straightforward and effective as possible.</div>
            <div className="contact">Contact Us</div>
            <div className="desContact"> Feel free to reach out to us at <strong>contactClikk@gmail.com</strong> for any inquiries. We value your feedback, so if you have a moment, please share your thoughts with us. Your input helps us improve and tailor our services to better meet your needs.Thank you for choosing Clikk. We appreciate your trust and look forward to serving you!</div>
        </div>

    );
};
export default Descriptions;