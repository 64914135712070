import React from 'react';
import './Privacy.css';
import './LandingPage.css';
import './Header.css';

const PrivacyPolicy = () => {
  return (
    <>
      <div className="linerGradient">
        <div className="logoContainer">
          <div className="logo">
            Clikk
          </div>
        </div>
      </div>
      <div>
        <h1>Privacy and Security Policy</h1>

        <h2>1. Introduction</h2>
        <p>
          Welcome to Clikk's Privacy and Security Policy. This document outlines how we collect, use, and protect the information you provide to us. Your privacy and the security of your information are of utmost importance to us. By using our website and services, you agree to the terms outlined in this policy.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          <strong>Personal Information</strong>
          <br />
          We may collect personal information, including but not limited to:
        </p>
        <ul>
          <li>First name</li>
          <li>Last name</li>
          <li>Phone number</li>
          <li>Email</li>
          <li>City and Province</li>
          <li>User preference, work or talent</li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>
          We use the information collected for the following purposes:
        </p>
        <ul>
          <li>Providing and improving our services</li>
          <li>Personalizing user experience</li>
          <li>Sending periodic emails or notifications</li>
          <li>Analyzing website performance</li>
          <li>Complying with legal obligations</li>
        </ul>

        <h2>4. Information Sharing</h2>
        <p>
          We do not sell, trade, or transfer your personal information to third parties without your consent. We may share information with trusted service providers for the sole purpose of assisting us in operating our website and services.
        </p>

        <h2>5. Consent</h2>
        <p>
          By using our website, you consent to the collection and use of your information as outlined in this Privacy and Security Policy.
        </p>

        <h2>6. Your Rights</h2>
        <p>
          You have the right to:
        </p>
        <ul>
          <li>Delete your personal information</li>
          <li>Opt-out of communications</li>
          <li>Withdraw consent for data processing</li>
        </ul>
        <p>
          By contacting us via email: <a href="mailto:contactClikk@gmail.com">contactClikk@gmail.com</a>, we will get back to you as soon as possible.
        </p>

        <h2>7. Changes to This Policy</h2>
        <p>
          We reserve the right to update or modify this Privacy and Security Policy at any time. Changes will be effective upon posting to the website. It is your responsibility to review this policy periodically.
        </p>

        <h2>8. Contact Us</h2>
        <p>
          If you have any questions, concerns, or requests regarding this policy, please contact us at <a href="mailto:contactClikk@gmail.com">contactClikk@gmail.com</a>.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;