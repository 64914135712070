// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth,GoogleAuthProvider } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAILlmabxXrUMIGH1yF6yRiXd74Qqerg4w",
  authDomain: "clikk-63918.firebaseapp.com",
  projectId: "clikk-63918",
  storageBucket: "clikk-63918.appspot.com",
  messagingSenderId: "853000173643",
  appId: "1:853000173643:web:7a199fea0a8978482ab5ce",
  measurementId: "G-R6NGMF4EWS",
  databaseURL: "https://clikk-63918-default-rtdb.firebaseio.com/"
};

export default firebaseConfig;