import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';


;
const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="footerCompanyContainer">
        <div className="footerCompanyName">
          Clikk
        </div>
        <div className="footerCompanyRight">
          &copy; 2023 Clikk, All right reserved.
        </div>
        <div className="footerCompanyRight">
          <Link to="/privacyPolicy" className="privacyPolicy">Privacy Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
